import { Box } from "@mui/material"
import React from "react"
import DataTable from "react-data-table-component"
import { Navigate } from "react-router-dom"
import { GetWinningPerAgent } from "../../services/AcceptedBetsServices"
import { useEffect, useState } from "react"
import * as moment from "moment"

const WinningBets = () => {
  var todayDate = moment().format("yyyy-MM-DD")
  const [data, setData] = useState([])
  const columns = [
    {
      name: "Time",
      selector: (row) => row.drawtime,
      // width: "30px",
      // wrap: true,
    },

    {
      name: "Combination",
      selector: (row) => row.combination,
    },
    {
      name: "Amount",
      selector: (row) => row.betamount,
    },
    {
      name: "Prize",
      selector: (row) => row.winningprize,
    },
  ]
  let user = localStorage.getItem("usercode")

  const LoadWinning = async () => {
    let data = {
      date: todayDate,
      usercode: user,
    }
    setData(await GetWinningPerAgent(data))
  }
  useEffect(() => {
    LoadWinning()
  }, [])
  return (
    <div>
      {user == null ? <Navigate to="/" replace={true} /> : null}
      <div className="card">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 20,
          }}
        >
          <label
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              color: "gray",
              fontSize: "20px",
            }}
          >
            WINNING BETS
          </label>
        </Box>
        <Box
          sx={{
            // display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 50,
          }}
        >
          <DataTable
            columns={columns}
            data={data}
            // onRowClicked={(row) => onClickBet(row)}
            // highlightOnHover
            fixedHeader
            pagination
          />
        </Box>
      </div>
    </div>
  )
}

export default WinningBets
