import axios from "axios"
import { useNavigate } from "react-router-dom"

// let navigate = useNavigate
const api = axios.create({
  baseURL: "/api",
})

api.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("accesstoken")
    req.headers.Authorization = `Bearer ${token}`
    return req
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      // originalRequest._retry = true
      try {
        localStorage.clear()
        window.location.replace("/")
      } catch (error) {
        window.location.replace("/")
      }
    }
    return Promise.reject(error)
  }
)
export default api
