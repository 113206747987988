import * as React from "react"
import Box from "@mui/material/Box"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import RestoreIcon from "@mui/icons-material/Restore"
import FavoriteIcon from "@mui/icons-material/Favorite"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import BluetoothIcon from "@mui/icons-material/Bluetooth"
import WidgetsIcon from "@mui/icons-material/Widgets"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"

import { Navigate, useNavigate } from "react-router-dom"
import {
  LocalAtm,
  Money,
  MoneyOff,
  MoneyOffCsred,
  MoneySharp,
  ExitToAppOutlined,
} from "@mui/icons-material"
import { blue } from "@mui/material/colors"

export default function SimpleBottomNavigation() {
  let user = localStorage.getItem("usercode")
  const [value, setValue] = React.useState(0)

  const navigate = useNavigate()

  const logout = () => {
    localStorage.clear()
    navigate("/")
  }
  return (
    <>
      {user != null ? (
        <Box sx={{ width: "100%" }}>
          <BottomNavigation
            sx={{
              width: "100%",
              position: "absolute",
              bottom: 5,
              backgroundColor: "#F1F9FF",
            }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
          >
            <BottomNavigationAction
              label="Main"
              icon={<WidgetsIcon />}
              onClick={() => navigate("/main")}
            />
            <BottomNavigationAction
              label="Bets"
              icon={<ConfirmationNumberIcon />}
              onClick={() => navigate("/bets")}
            />
            <BottomNavigationAction
              label="Winning Bets"
              icon={<LocalAtm />}
              onClick={() => navigate("/winning")}
            />
            <BottomNavigationAction
              label="Logout"
              icon={<ExitToAppOutlined />}
              onClick={() => logout()}
            />
          </BottomNavigation>
        </Box>
      ) : null}
    </>
  )
}
