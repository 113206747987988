// import logo from "./logo.svg"
import "./App.css"
import SimpleBottomNavigation from "./tabs/layout/SimpleBottomNavigation"
import React, { Component, Suspense, useEffect } from "react"
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import WinningBets from "./tabs/winning/WinningBets"
import Passcode from "./login/Passcode"
import UserPass from "./login/UserPass"
import AcceptedBets from "./tabs/acceptedbets/AcceptedBets"
import AcceptedBetsCeb from "./tabs/acceptedbets/AcceptedBetsCeb"
import "primereact/resources/themes/mdc-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import { detectIncognito } from "detectincognitojs"
import { useState } from "react"

const MainUi = React.lazy(() => import("./tabs/main/MainUi"))
const MainUiCeb = React.lazy(() => import("./tabs/main/MainUiCeb"))
const Test = React.lazy(() => import("./tabs/test/PrintTest"))

const ConnectPrinter = React.lazy(() => import("./tabs/printer/ConnectPrinter"))
const Incognito = React.lazy(() => import("./nonincognito/index"))

function App() {
  let user = localStorage.getItem("usercode")
  const [isPrivate, setisPrivate] = useState(null)
  detectIncognito().then((result) => {
    setisPrivate(result.isPrivate)
  })

  return (
    <>
      {/* {user != null ? <Navigate to="/main" replace={true} /> : null} */}
      <SimpleBottomNavigation />
      <Suspense>
        <Routes>
          {isPrivate ? (
            <>
              <Route
                path="/winning"
                name="Login Page"
                element={<WinningBets />}
              />
              <Route path="/main" name="Login Page" element={<MainUiCeb />} />
              <Route
                path="/bets"
                name="Bets History"
                element={<AcceptedBetsCeb />}
              />
              <Route path="/" element={<Passcode />} />
            </>
          ) : (
            <Route path="/*" element={<Incognito />} />
          )}
        </Routes>
      </Suspense>
    </>
  )
}

export default App
